import { MDBContainer, MDBRow, MDBCol, MDBBtn,MDBRadio } from "mdb-react-ui-kit"
import { useState,useEffect } from "react";

const PricingPlan = () => {
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
      if (value === basicActive) {
        return;
      }
  
      setBasicActive(value);
    };
    const [isMonthly, setIsMonthly] = useState(true);
    const [exchangeRate, setExchangeRate] = useState(87.43); // Default rate
    const [usdPrice, setUsdPrice] = useState(30);
    const [inrPrice, setInrPrice] = useState((30 * exchangeRate).toFixed(2));
    useEffect(() => {
        // Fetch the latest exchange rate
        fetch("https://api.exchangerate-api.com/v4/latest/USD")
            .then(response => response.json())
            .then(data => setExchangeRate(data.rates.INR))
            .catch(error => console.error("Error fetching exchange rate:", error));
    }, []);

    useEffect(() => {
        const newUsdPrice = isMonthly ? 30 : 330;
        setUsdPrice(newUsdPrice);
        setInrPrice((newUsdPrice * exchangeRate).toFixed(2));
    }, [isMonthly, exchangeRate]);
    return(
        <section className="pricing-plan" id="pricing">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12" lg="12" sm="12" xs="12" className="self-center text-center">
                        <div className="pricing-plan-content">
                            <h5 className="section-title">Pricing & Plans</h5>
                            <p>The pricing of this software is purely based on usability and scalability. It is designed to be affordable for global access.</p>
                            <h3 class="what-hear-instruction-title">Mission Statement</h3>
                            <p>{/* We at HearSay envision us growing into a large community of providers in a variety of fields, to be able to collaborate, and coordinate services for individuals with functional, social, emotional, and academic developmental delays and communication deficits. {/* Our goal is to reach every individual We are continuously striving to keep the pricing affordable to reach every individual with limited access to therapies, education, and other services. */}
                            Build a collaborative worldwide network of Audiologists and Speech Language Pathologists for providing quality service to those who do not have access to trained professionals within their immediate communities.</p>
                        </div>
                    </MDBCol>
                    <MDBCol md="12" lg="12" sm="12" xs="!2" className="mobile-col-sep mt-5">
                        <MDBRow>
                            <MDBCol md="4" lg="4" sm="4" xs="12" className="ipad-w-100 mobile-space-top">
                                <div className="pricing-plan-block hover-overlay shadow-1-strong">
                                    <div className="plan-details">
                                        <h6 className="plan-title">Monthly <br/>Plan</h6>
                                        
                                        <h2 className="plan-price">$ 30</h2>
                                    </div>
                                    <div className="button">
                                        <MDBBtn className="btn-primary" onClick={() => window.open(`https://ui.hearsaymeet.com/#/login`,'__blank')}>Subscribe <sup>*</sup></MDBBtn>
                                        <div className="mt-3 register-note">(Registration is compulsory for subscription.)</div>
                                        <p>Renewable Monthly Plan</p>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol md="4" lg="4" sm="4" xs="12" className="ipad-w-100 mobile-space-top">
                                <div className="pricing-plan-block hover-overlay shadow-1-strong">
                                    <div className="plan-details">
                                        <h6 className="plan-title">Yearly <br/>Plan</h6>
                                        <h2 className="plan-price">$ 330</h2>
                                    </div>
                                    <div className="button">
                                        <MDBBtn className="btn-primary" onClick={() => window.open(`https://ui.hearsaymeet.com/#/login`,'__blank')}>Subscribe <sup>*</sup></MDBBtn>
                                        <div className="mt-3 register-note">(Registration is compulsory for subscription.)</div>
                                        <p>Renewable Yearly Plan</p>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol md="4" lg="4" sm="4" xs="12" className="ipad-w-100 mobile-space-top">
                                <div className="pricing-plan-block hover-overlay shadow-1-strong">
                                    <div className="plan-details price-convertor">
                                        <h6 className="plan-title">Price Convertor(USD to INR)</h6>
                                        <div className="flex">
                                            <MDBRadio name='flexRadioDefault' id='flexRadioDefault1' label='Monthly' defaultChecked onChange={() => setIsMonthly(true)} />
                                            <MDBRadio name='flexRadioDefault' id='flexRadioDefault2' label='Yearly' onChange={() => setIsMonthly(false)}  />
                                        </div>
                                        <h2 className="plan-price">USD: $ {usdPrice}</h2>
                                        <h2 className="plan-price">INR: Rs {inrPrice}</h2>
                                    </div>
                                    <div className="button">
                                        <MDBBtn className="btn-primary" onClick={() => window.open(`https://ui.hearsaymeet.com/#/login`,'__blank')}>Subscribe <sup>*</sup></MDBBtn>
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <p className="hightlight-text mt-3">Limited time introductory offer: Cancel anytime within the first month after signing on for a full refund, following a 30 minute exit interview</p>
                
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    )
}
export default PricingPlan;