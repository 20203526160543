import axios from "axios";
import { MDBCol, MDBContainer, MDBRow, MDBValidation, MDBBtn } from "mdb-react-ui-kit"
import { useState } from "react";
import ScheduleDemoImage from '../assets/HearSay_Video.jpg'
const ScheduleDemo = () => {
    const [scheduleDemo, setscheduleDemo] = useState({
        fullName: '',
        clinicName: '',
        email: '',
        mobileNumber: ''
      });
      const [errors,setErrors]=useState()
      const onChange = (e) => {
        setscheduleDemo({ ...scheduleDemo, [e.target.name]: e.target.value });
      };
    const [validated, setValidated] = useState(false);
    const handleSubmit = (e) => {
    const form = e.currentTarget;
    if(scheduleDemo.mobileNumber.length<0) {
      setErrors(true)
      e.preventDefault();
      e.stopPropagation();
    }
    else if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    else {
      if(scheduleDemo.mobileNumber.length!==10){
        setErrors(true);
      }
      else {
        e.preventDefault();
        axios.post('apis/submission.php',scheduleDemo).then((res)=>{
          if(res.data.success===1) {
              alert('Thanks for contacting us. our team will get back to you soon')
              window.location.reload();
            }
            else {
              alert('something went wrong Please try again')
            }
          })
        console.log(scheduleDemo)
        setErrors(false)
      }
    }
    setValidated(true);
  };
    return(
        <section className="schedule-demo" id="schedulecall">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="9" lg="9" sm="9" xs="12" className="ipad-w-60">
                        <div className="image">
                            <img src={ScheduleDemoImage} alt="Schedule a demo" title="Schedule a demo" className="w-100" />
                        </div>
                    </MDBCol>
                    <MDBCol md="3" lg="3" sm="3" xs="12" className="mobile-col-sep schedule-book-section ipad-w-40">
                        <h6 className="schedule-title">Schedule a demo</h6>
                        <p>Request an appointment now. Our product managers are ready to help you and answer your questions.</p>
                        <MDBValidation className='row g-3' noValidate validated={validated} onSubmit={handleSubmit}>
                            <MDBCol md="12" lg="12" sm="12" xs="12">
                              <div className="form-group">
                                  <input type="text" placeholder="Full name" className="form-control" value={scheduleDemo.fullName} name="fullName" onChange={onChange} />
                              </div>
                            </MDBCol>
                            <MDBCol md="12" lg="12" sm="12" xs="12">
                              <div className="form-group has-validation">
                                  <input type="text" placeholder="Clinic name *" className="form-control" value={scheduleDemo.clinicName} name="clinicName" onChange={onChange} required validation="Please Enter Clinic Name" invalid />
                                  <div className='invalid-feedback'>Please Enter Clinic Name</div>
                              </div>
                            </MDBCol>
                            <MDBCol md="12" lg="12" sm="12" xs="12">
                              <div className="form-group">
                                 <div className='form-group has-validation'>
                                    <input type="email" placeholder="Email *" className="form-control" value={scheduleDemo.email} name="email" onChange={onChange} required validation="Please Enter Email Id" invalid />
                                    <div className='invalid-feedback'>Please Enter Email Id</div>
                                  </div>
                              </div>
                            </MDBCol>
                            <MDBCol md="12" lg="12" sm="12" xs="12">
                              <div className="form-group">
                                  <div className='form-group has-validation'>
                                    <input
                                      type="number"
                                      className={errors?'form-control is-invalid' :'form-control'}
                                      value={scheduleDemo.mobileNumber} name="mobileNumber" placeholder="Contact Number *" onChange={onChange} required 
                                    />
                                     {errors===true ?
                                      <div className='invalid-feedback'>Please Enter Correct Mobile Number</div>:null
                                     }
                                  </div>
                              </div>
                            </MDBCol>
                            <div className='col-12 mt-3'>
                                <MDBBtn type='submit' className='btn-secondary'>Request a demo</MDBBtn>
                            </div>
                        </MDBValidation>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    )
}
export default ScheduleDemo;