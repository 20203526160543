/* eslint-disable jsx-a11y/anchor-is-valid */
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit"
import Logo from '../assets/colored-logo.png';
import Facebook from '../assets/Facebook.png'
import Twitter from '../assets/twitter.png'
import Instgram from '../assets/instagram.png'
import Youtube from '../assets/youtube.png'
const Footer = () => {
    return(
        <section className="footer">
            <MDBContainer>
                <MDBRow className="mb-5">
                    <MDBCol md="3" lg="3" sm="3" xs="12">
                        <div className="footer-logo">
                            <img src={Logo} alt="footer-logo" title="footer-logo" />
                        </div>
                    </MDBCol>
                    <MDBCol md="3" lg="3" sm="3" xs="12" className="mobile-col-sep">
                        <div className="footer-content">
                            <h6 className="footer-title">For any queries</h6>
                            <h5 className="footer-content-link">Email: <a href="mailto:info@hearsay.com" rel="noreferrer" target="_blank">info@hearsay.com</a></h5>
                            <h5 className="footer-content-link">Contact No: <a href="tel:+919701222770" rel="noreferrer" target="_blank">+91 9701222770</a></h5>
                        </div>
                    </MDBCol>
                    <MDBCol md="6" lg="6" sm="6" xs="12" className="mobile-col-sep">
                        <MDBRow>
                            <MDBCol md="6" lg="6" sm="6" xs="12">
                                <div className="footer-content">
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/`} rel="noreferrer">Home</a></h5>
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/#features`} rel="noreferrer">Features</a></h5>
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/#pricing`} rel="noreferrer">Pricing & Plans</a></h5>
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/#schedulecall`} rel="noreferrer">Schedule a call</a></h5>
                                </div>
                            </MDBCol>
                            <MDBCol md="6" lg="6" sm="6" xs="12">
                                <div className="footer-content">
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/support`} rel="noreferrer">Customer Support</a></h5>
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/refund-policy`} rel="noreferrer">Refund & Cancellation Policy</a></h5>
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/terms-conditions`} rel="noreferrer">Terms & Conditons</a></h5>
                                    <h5 className="footer-content-link"><a href={`${process.env.PUBLIC_URL}/privacy-policy`} rel="noreferrer">Privacy Policy</a></h5>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        
                    </MDBCol>
                    {/* <MDBCol md="3" lg="3" sm="3" xs="12" className="mobile-col-sep">
                        <ul className="social-links">
                            <li><a href="" target="_blank" rel="noreferrer"><img src={Facebook} alt="Facebook" title="Facebook" /></a></li>
                            <li><a href="" target="_blank" rel="noreferrer"><img src={Twitter} alt="Twitter" title="Twitter" /></a></li>
                            <li><a href="" target="_blank" rel="noreferrer"><img src={Youtube} alt="Youtube" title="Youtube" /></a></li>
                            <li><a href="" target="_blank" rel="noreferrer"><img src={Instgram} alt="Instgram" title="Instgram" /></a></li>
                        </ul>
                    </MDBCol> */}
                </MDBRow>
                <section className="copyright text-center">
                    <MDBRow>
                        <MDBCol md="12" lg="12" sm="12" xs="12">
                            <h4 className="copyRight-title">Copyright ©2021 All rights reserved</h4>
                        </MDBCol>
                    </MDBRow>
                </section>
            </MDBContainer>
        </section>
    )
}
export default Footer;